<template>
  <form ref="form" @submit.stop.prevent="updateForm">
    <b-row>
      <b-col style="min-height: 170px" class="mt-4">
        <div
          style="
            width: 150px;
            height: 150px;
            background-color: #f3f6f9;
            box-shadow: 0px 4px 4px 0px #000000 10%;
            position: relative;
            margin: auto;
          "
        >
          <v-file-input
            hide-input
            v-model="file"
            accept="image/png, image/jpeg, image/bmp"
            class="icon-edit"
            @change="onUpload"
            prepend-icon="mdi-pencil-outline"
            truncate-length="15"
          >
          </v-file-input>
          <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
          <!-- <v-icon
            @click="clear"
            v-if="preview"
            style="position: absolute; bottom: -10px; right: -10px"
          >
            mdi-cancel</v-icon
          > -->
        </div>

        <label
          for=""
          class="d-flex flex-column align-items-center justify-content-center"
          >Icon cảm xúc
          <span class="d-block">(sẽ được sử dụng trên mobile app)</span></label
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <basic-input
          :required="true"
          label="Mã cảm xúc"
          name="code"
          v-validate="'required|alpha_num'"
          data-vv-as="Mã cảm xúc"
          :state="validateState('code')"
          :invalidFeedback="errors.first('code')"
          :value.sync="form.code"
          placeholder="Nhập mã cảm xúc"
        ></basic-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <basic-input
          :required="true"
          label="Tên cảm xúc"
          name="name"
          data-vv-as="Tên cảm xúc"
          v-validate="'required'"
          :state="validateState('name')"
          :invalidFeedback="errors.first('name')"
          :value.sync="form.name"
          placeholder="Nhập tên cảm xúc"
        ></basic-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <basic-select
          label="Nhóm cảm xúc"
          placeholder="--- Chọn nhóm cảm xúc ---"
          name="groupFeeling"
          v-validate="'required'"
          data-vv-as="Nhóm cảm xúc"
          :state="validateState('groupFeeling')"
          :invalidFeedback="errors.first('groupFeeling')"
          :options="categories"
          :disabled="isEditing"
          :value.sync="form.emotionType"
          track-by="value"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <basic-text-area
          label="Ghi chú"
          placeholder="Ghi chú"
          name="note"
          :value.sync="form.description"
        ></basic-text-area>
      </b-col>
    </b-row>
  </form>
</template>

<script>
export default {
  name: 'FeelingForm',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    categories: {
      type: Array,
    },
    isEditing: {
      type: Boolean,
    },
  },
  watch: {
    data: {
      handler(newVal) {
        let clone = JSON.parse(JSON.stringify(newVal));
        this.form = clone;
        if (this.isEditing) {
          this.form.id = newVal.id;
          this.form.emotionType = this.categories.find(
            (e) => e.value === clone.emotionType,
          );
          this.preview = clone.icon?.url;
          // this.file = clone.icon?.url;
        }
      },
      deep: true,
    },
  },
  computed: {
    category() {
      return this.categories;
    },
  },
  data() {
    return {
      snackbar: true,
      filter: {},
      file: null,
      preview: null,
      form: {
        emotionType: null,
        description: '',
        code: null,
        images: null,
        englishName: null,
        name: null,
        status: 0,
      },
      rules: [],
    };
  },

  methods: {
    clear() {
      this.file = null;
      this.preview = null;
      this.form.images = null;
    },
    onUpload() {
      this.preview = URL.createObjectURL(this.file);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    updateInactive() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        let formData = new FormData();
        this.isEditing && formData.append('id', this.form.id);
        this.file && formData.append('image', this.file);
        formData.append('name', this.form.name);
        formData.append(
          'emotionType',
          typeof this.form.emotionType == 'object'
            ? this.form.emotionType.value
            : this.form.emotionType,
        );
        formData.append('code', this.form.code);
        this.form.description &&
          formData.append('description', this.form.description);
        formData.append('isActive', !this.form.isActive);
        this.$emit('updateInactive', formData);
      });
    },
    updateForm() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        let formData = new FormData();
        this.isEditing && formData.append('id', this.form.id);
        this.file && formData.append('image', this.file);
        formData.append('name', this.form.name);
        formData.append(
          'emotionType',
          typeof this.form.emotionType == 'object'
            ? this.form.emotionType.value
            : this.form.emotionType,
        );
        formData.append('code', this.form.code);
        this.form.description &&
          formData.append('description', this.form.description);

        this.$emit('updateForm', formData);
      });
    },
    onChange() {},
  },
};
</script>

<style lang="scss" scoped>
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 100000;
  position: absolute;
  top: -30px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
</style>
